<template>
    <div class="centered-message w-100">
        <h1 class="text-primary mb-5">Stella™ is Currently Offline</h1>
        <div class="errm">
            <p>Stella is undergoing system updates and/or experiencing technical issues.</p>
            <p>Our team is working diligently to restore service as quickly as possible.</p>
            <p>We appreciate your patience during this time.</p>
            <p class="mt-4">
                For any questions or assistance, please reach out to our customer service team:
            </p>
            <p class="mt-4">
                <strong>
                    US:
                    <a href="mailto:customerservice@staar.com"> customerservice@staar.com </a>
                </strong>
            </p>
            <p>
                <strong>
                    International:
                    <a href="mailto:customerservice.ag@staar.com"> customerservice.ag@staar.com </a>
                </strong>
            </p>
        </div>
    </div>
</template>
<script>
import store from '@/store';

export default {
    name: 'Unavailable',
    data() {
        return {
            checkAt: 1,
        };
    },
    mounted() {
        this.checkTimer = setTimeout(this.checkAPI.bind(this), this.checkAt * 1000);
    },
    beforeDestroy() {
        clearTimeout(this.checkTimer);
    },
    methods: {
        /**
         * Check for API availability by pinging the endpoint associated with user/fetchUser
         * with an exponential backoff. Navigate to Dashboard and reload if API becomes available.
         */
        checkAPI() {
            store
                .dispatch('user/fetchUser', false)
                .then(async () => {
                    await this.$router.push({name: 'Dashboard'});
                    location.reload();
                })
                .catch((e) => {
                    if (e.isAxiosError && !e.request.status) {
                        this.checkTimer = setTimeout(
                            this.checkAPI.bind(this),
                            (this.checkAt = 2 ** this.checkAt) * 1000
                        );
                    }
                });
        },
    },
};
</script>
<style scoped>
.errm {
    font-size: 30px;
}
</style>
